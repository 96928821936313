import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Recaptcha from 'react-google-recaptcha';
import media from '../tools/mediaQueries';
import Layout from '../layouts';
import Hero from '../components/Hero';

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY;

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

class Contact extends React.Component {
  state = {};

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRecaptcha = value => {
    this.setState({ 'g-recaptcha-response': value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(
        () => alert('Message sent.')
    
      )
      .catch(() => alert('Something went wrong'));
  };
  render() {
    return (
      <Layout>
      <Grid>
        <Helmet title="STERN & STERN - Contact" />
        <StyledRow>
          <Hero title="Contact us" />
          <Lead xs={12}>
            <h4>
              Stern & Stern, P.C. • 3002 Merrick Road • Bellmore, NY • 11710 T: (516) 771-0710 • F: (516) 771-0723
            </h4>
          </Lead>
        </StyledRow>
        <StyledRow>
          <Col xs={12}>
            <GoogleMap>
              <iframe
                title="googleMaps"
                width="940"
                height="390"
                frameBorder="0"
                style={{ border: '0px none' }}
                src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d3026.4737236264327!2d-73.515458!3d40.66353!3m2!1i1024!2i768!4f13.1!2m1!1sSTERN+%26+STERN%2C+P.C.+3002+MERRICK+ROAD++BELLMORE%2C+NY+%E2%80%A2+11710+!5e0!3m2!1sen!2sin!4v1426308288800"
              />{' '}
            </GoogleMap>
          </Col>
        </StyledRow>
        <FormWrapper>
          <Form
            name="contact"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            method="post"
            data-netlify-recaptcha
            onSubmit={this.handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <StyledRow>
              <Col xs={12} sm={5} smOffset={1}>
                <Row>
                  <Col xs={12}>
                    <p>Please email us directly :</p>
                  </Col>
                  <Col xs={12}>
                    <label htmlFor="contactName">
                      Client Name<span>*</span>
                      <input
                        type="text"
                        name="ContactName"
                        id="contactName"
                        maxLength="60"
                        required
                        onChange={this.handleChange}
                      />
                    </label>
                  </Col>
                  <Col xs={12}>
                    <label htmlFor="contactEmail">
                      Email address<span>*</span>
                      <input
                        type="email"
                        name="ContactEmail"
                        id="contactEmail"
                        maxLength="100"
                        required
                        onChange={this.handleChange}
                      />
                    </label>
                  </Col>
                  <Col xs={12}>
                    <label htmlFor="contactPhone">
                      Phone #<span>*</span>
                      <input
                        type="text"
                        name="ContactPhone"
                        id="contactPhone"
                        maxLength="20"
                        required
                        onChange={this.handleChange}
                      />
                    </label>
                  </Col>
                  <Col xs={12}>
                    <label htmlFor="contactZip">
                      Zip<span>*</span>
                      <input
                        type="text"
                        name="ContactZip"
                        id="contactZip"
                        maxLength="60"
                        required
                        onChange={this.handleChange}
                      />
                    </label>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={5} className="margin-top">
                <Row>
                  <Col xs={12}>
                    <p>* = indicates mandatory field</p>
                  </Col>
                  <Col xs={12}>
                    <label htmlFor="contactInstructions">
                      Instructions<span>*</span>
                      <textarea
                        name="ContactInstructions"
                        id="contactInstructions"
                        cols="10"
                        rows="4"
                        required
                        onChange={this.handleChange}
                      />
                    </label>
                  </Col>
                  <Col xs={12}>
                    {/* <Recaptcha ref="recaptcha" sitekey={RECAPTCHA_KEY} onChange={this.handleRecaptcha} /> */}
                  </Col>
                  <Col xs={12}>
                    <button type="submit">Submit</button>
                  </Col>
                </Row>
              </Col>
            </StyledRow>
          </Form>
        </FormWrapper>
        <StyledRow>
          <Col xs={12}>
            <p className="terms-info">
              Until an attorney-client relationship has been established, confidential information should not be
              submitted to the firm.{' '}
            </p>
          </Col>
        </StyledRow>
      </Grid>
      </Layout>
    );
  }
}

export default Contact;

const StyledRow = styled(Row)`
  background-color: #fff;
  padding-bottom: 30px;

  .margin-top {
    margin-top: 30px;

    ${media.sm`
      margin-top: 0;
    `};
  }

  .terms-info {
    font-size: 12px;
    text-align: right;
    line-height: 16px;
    font-family: 'Raleway';
    font-weight: 500;
  }
`;

const Lead = styled(Col)`
  text-align: center;
  margin-top: 50px;

  ${media.sm`
    margin-top: 0;
  `};

  h4 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin: 10px auto 30px;
    text-transform: uppercase;
    text-align: center;

    ${media.sm`
      width: 80%;
    `};

    ${media.md`
      width: 65%;
    `};
  }
`;

const GoogleMap = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Form = styled.form`
  width: 100%;

  p {
    font-family: 'Raleway';
    font-size: 15px;
    font-weight: 700;
    color: #a85701;
    text-align: left;
    margin-bottom: 10px;
  }

  span {
    color: #a85701;
    font-weight: 700;
    font-size: 15px;
    line-height: 32px;
  }

  label {
    padding: 2px 0;
    line-height: 32px;
    font-size: 15px;
    color: #4d4d4d;
    display: block;
    font-weight: 700;
    line-height: 1.5;
    font-family: 'Open Sans';

    ${media.sm`
      font-size: 18px;
    `};
  }

  input,
  textarea {
    border-radius: 0;
    background-color: #ffffff;
    font-family: inherit;
    border-style: solid;
    border-width: 2px;
    border-color: #c5d0d2;
    color: rgba(0, 0, 0, 0.75);
    display: block;
    font-size: 0.875rem;
    margin: 0 0 3px 0;
    padding: 0.5rem;
    height: 33px;
    width: 100%;
    transition: all 0.15s linear;

    &:focus {
      background: #fafafa;
      border-color: #999999;
      outline: none;
    }
  }

  textarea {
    width: 100%;
    line-height: 33px;
    height: auto;
  }

  button {
    border-radius: 0;
    border: 2px solid #c5d0d2;
    width: 100%;
    height: 34px;
    background: rgb(133, 3, 0);
    color: rgba(255, 255, 255, 1);
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 700;
    cursor: pointer;
    outline: none;
  }
`;

const FormWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
`;
